<template>
<div>
    <!-- navbar -->
    <Navbar />
    <div id="layoutSidenav">
        <Menu />
        <spinner></spinner>
        <div id="layoutSidenav_content">
            <main>
                <header class="page-header page-header-dark bg-gradient-primary-to-secondary mb-4">
                    <div class="container-xl px-4">
                        <div class="page-header-content pt-4">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto mt-4">
                                    <h1 class="page-header-title">
                                        <div class="page-header-icon"><i class="fas fa-folder-plus"></i></div>
                                        Gestiones Disponibles
                                    </h1>
                                    <div class="page-header-subtitle">Seleccioná la gesti&oacute;n que necesites realizar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="container-xl px-4">
                    <!-- GESTIONES-->
                    <div class="accordion" id="accordionPanelsStayOpenExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                    <h4 class="mb-0">Gestiones</h4>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                <div class="accordion-body">
                                    <div class="row" v-if="this.tramites.length != 0">
                                        <div class="col-md-4 mb-4" v-for="(tramite,index) in this.tramites" :key="index">
                                            <CardTramite colorTitle="text-primary" tipo_tramite="gestion" :title="tramite.descripcion" :desc="tramite.portal_leyenda" :url="tramite.portal_strategy" :informacion_modal="tramite.portal_informacion" :defTramiteID="tramite.id" />
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-md-4 mb-4">
                                            <CardTramite colorTitle="text-primary" tipo_tramite="consulta" title="No existen gestiones disponibles" desc="" disabled="disabled" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- LEGAJO -->
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                                    <h4 class="mb-0"> Legajo </h4>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                                <div class="accordion-body">
                                    <div class="row" v-if="this.tramitesLegajo.length != 0">
                                        <div class="col-md-4 mb-4" v-for="(tramite,index) in this.tramitesLegajo" :key="index">
                                            <CardTramite colorTitle="text-primary" tipo_tramite="gestion" :title="tramite.descripcion" :desc="tramite.portal_leyenda" :url="tramite.portal_strategy" :informacion_modal="tramite.portal_informacion" :defTramiteID="tramite.id" />
                                        </div>
                                    </div>
                                  <div class="row" v-else>
                                        <div class="col-md-4 mb-4">
                                            <CardTramite colorTitle="text-primary" tipo_tramite="consulta" title="No existen gestiones disponibles" desc="" disabled="disabled" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    </div>

</div>
</template>

<style>
.accordion-button {
    background: #f5f5f5 !important;
    color: black !important;
}

.accordion-body {
    background: #f5f5f5 !important;
}

.accordion-item {
    border: none;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import spinner from '../../../components/spinner'
import Vue from 'vue'
import Navbar from '../../../components/home/navbar/navbar'
import Menu from '../../../components/home/menu'
import CardButton from '../../../components/home/step/util/cardButton'
import CardTramite from '../../../components/home/step/util/cardTramites'
export default {
    name: "Home",
    components: {
        spinner,
        Navbar,
        Menu,
        CardButton,
        CardTramite
    },
    mounted: function () {
        this.addMenu();
        this.$store.commit("proveedor/SET_ESPECIALIDADES_CLEAR", "");
        this.$store.commit("proveedor/SET_TRAMITE_INGRESADO", "");
        this.getListaTramites();

    },
    methods: {
        addMenu() {
            document.body.classList.remove("sidenav-toggled");
        },
        getListaTramites() {
            this.$store.dispatch("tramiteGenerico/getTramites", "GESTION");
            this.$store.dispatch("tramiteGenerico/getTramitesLegajo", "LEGAJO");
        },
    },
    computed: {
        tramites: {
            get() {
                return this.$store.state.tramiteGenerico.tramites;
            }
        },
        tramitesLegajo: {
            get() {
                return this.$store.state.tramiteGenerico.tramites_legajo;
            }
        },
    },
};
</script>
